import { Anchor, Tab } from '@qogita/ui';
import Image from 'next/legacy/image';

import { NextAnchor } from '#components/NextAnchor';
import { vercelImageLoader } from '#lib/url';

import authenticityIcon from '../assets/benefits/authenticity.svg';
import authenticityImg from '../assets/benefits/authenticity.webp';
import intuitiveImg from '../assets/benefits/intuitive.webp';
import intuitiveIcon from '../assets/benefits/intuitive-icon.svg';
import logisticsImg from '../assets/benefits/logistics.webp';
import logisticsIcon from '../assets/benefits/logistics-icon.svg';
import paymentsImg from '../assets/benefits/payments.webp';
import paymentsIcon from '../assets/benefits/payments-icon.svg';
import pricesImg from '../assets/benefits/prices.webp';
import pricesIcon from '../assets/benefits/prices-icon.svg';

const benefits = [
  {
    name: 'Intuitive platform',
    image: intuitiveImg,
    icon: intuitiveIcon,
    title: 'All your procurement needs, on a unified platform',
    description:
      'Order, pay and track deliveries in a single place, saving you precious time and unnecessary frustration. It’s quick and easy to discover new or identify desired goods by self-searching, uploading specific GTINs or even ordering through our API. Financing and shipping insurance are built in and can be added at checkout.',
  },
  {
    name: 'Unmatched prices',
    image: pricesImg,
    icon: pricesIcon,
    title: 'Find the best prices from Qogita’s vast global supplier network ',
    description:
      'We have built - and continue to grow - a large network of suppliers worldwide, helping us to source a vast array of markets. Our model keeps prices extremely competitive and allows all-sized businesses to compete in the marketplace.',
  },
  {
    name: 'Flexible payments',
    image: paymentsImg,
    icon: paymentsIcon,
    title: 'Buy now, pay later with flexible financing',
    description: (
      <>
        Ease your cash flow, defer your payments by 30, 60 or 90 days; to sell
        your products before paying your invoice. For more information on
        accessing financing, see <NextAnchor href="/financing">here</NextAnchor>
        .
      </>
    ),
  },
  {
    name: 'Easy logistics',
    image: logisticsImg,
    icon: logisticsIcon,
    title: `Simplified logistics. 
  Zero paperwork.`,
    description:
      "Qogita handles all shipments for you, there's no need to arrange deliveries. Plus, we'll complete any frustrating paperwork associated with your orders.",
  },
  {
    name: 'Authenticity guaranteed',
    image: authenticityImg,
    icon: authenticityIcon,
    title: `Protection against counterfeit goods`,
    description: (
      <>
        We guarantee the authenticity of all products sold via Qogita. We hold
        our sellers to the highest standards of integrity and work closely with
        them to ensure that every product they provide meets our strict quality
        criteria. For more information on our authenticity guarantee, see{' '}
        <Anchor href="https://help.qogita.com/authenticity-guarantee">
          here.
        </Anchor>
      </>
    ),
  },
];

export const BenefitsPanel = () => {
  return (
    <section className="flex flex-col items-center bg-[#D6E2ED] pt-12 md:pt-24">
      <div className="w-full max-w-5xl">
        <h2 className="mx-4 mb-3 text-center text-4xl font-bold md:mx-6">
          Access to our benefits
        </h2>
        <div className="mx-4 flex flex-col items-center md:mx-6">
          <Tab.Group>
            <Tab.List className="mx-auto mb-8 max-w-full gap-3 overflow-auto px-0 leading-5 sm:mb-12 md:gap-6 md:leading-6">
              {benefits.map(({ name }) => (
                <Tab key={name}>{name}</Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              {benefits.map(({ name, image, icon, title, description }) => (
                <Tab.Panel key={`${name} panel`}>
                  <div className="flex flex-col md:grid md:grid-cols-[3fr_2fr] md:gap-8">
                    <div className="order-last grid items-end md:order-first md:mt-0">
                      <Image
                        loader={vercelImageLoader}
                        src={image}
                        alt={name}
                        layout="responsive"
                        objectFit="contain"
                      />
                    </div>
                    <div className="flex flex-col items-start justify-center">
                      <Image
                        loader={vercelImageLoader}
                        src={icon}
                        alt={`${name} icon`}
                      />
                      <h3 className="mt-6 text-xl font-bold md:mt-4 md:text-3xl">
                        {title}
                      </h3>
                      <p className="my-6 text-gray-700 md:mt-4">
                        {description}
                      </p>
                    </div>
                  </div>
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </section>
  );
};
