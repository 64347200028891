import { Anchor } from '@qogita/ui';
import Image from 'next/legacy/image';

import { NextAnchor } from '#components/NextAnchor';
import { vercelImageLoader } from '#lib/url';

import heroPackageImg from '../assets/hero-package.webp';
import heroPackageMobileImg from '../assets/hero-package-mobile.webp';

const Banner = (): JSX.Element => {
  return (
    <div className="text-primary-100 mx-4 max-w-5xl rounded-lg bg-[url('/homepage/banner-background.webp')] bg-cover bg-[right_-12rem_bottom] bg-no-repeat px-4 py-28 text-center text-3xl md:mx-6 md:bg-center md:px-36 md:py-24">
      <p className="md:py-2">
        Browse our catalog of <strong>150,000+ SKUs</strong> and{' '}
        <strong>€500M+ worth of stock</strong>, all available and on the
        warehouse floor of our <strong>200+ sellers</strong> in Europe.
      </p>
      <p className="mt-8 md:mt-4 md:py-2">
        Competitively priced and ready to ship in a few days.
      </p>
    </div>
  );
};

export const HeroPanel = () => {
  return (
    <section className="home-page-hero-gradient flex flex-col items-center pb-12 md:bg-cover md:pt-20">
      <div className="mb-24 max-w-5xl items-center md:mb-32 md:ml-6 md:grid md:grid-cols-2 md:gap-x-14">
        <div className="block px-4 md:hidden">
          <Image
            loader={vercelImageLoader}
            src={heroPackageMobileImg}
            layout="intrinsic"
            objectFit="contain"
            objectPosition="top"
            alt="Qogita box"
          />
        </div>
        <div className="mx-4 text-center md:mx-0 md:text-left">
          <h1 className="text-center text-5xl font-bold md:text-left">
            The smartest way to buy wholesale
          </h1>
          <p className="mt-4 text-gray-700">
            Connect with thousands of suppliers and save time and money with our
            one-stop wholesale marketplace for branded products.
          </p>
          <div className="mt-8 flex flex-col gap-y-2 text-center md:flex-row md:gap-x-2">
            <NextAnchor href="/products/" variant="primaryContained">
              Buy via Qogita
            </NextAnchor>
            <Anchor
              variant="primaryOutlined"
              href="https://sellers.qogita.com/"
            >
              Sell via Qogita
            </Anchor>
          </div>
        </div>
        <div className="hidden md:block">
          <Image
            loader={vercelImageLoader}
            src={heroPackageImg}
            layout="intrinsic"
            alt="Qogita box"
            className="hidden md:block"
          />
        </div>
      </div>
      <div className="px-4 md:px-0">
        <Banner />
      </div>
    </section>
  );
};
