export const FinalPanel = () => {
  return (
    <section className="flex justify-center bg-gray-100 px-4 py-24 text-center md:py-40">
      <div className="mx-4 max-w-5xl md:mx-6 md:py-2">
        <p className="text-primary-700 text-4xl font-bold">Anticipate, adapt</p>
        <p className="text-4xl font-bold">and grow around the globe.</p>
      </div>
    </section>
  );
};
