import { useEffect, useState } from 'react';

const useMatchMedia = ({
  mediaQueryString,
  initialState,
}: {
  mediaQueryString: string;
  initialState: boolean;
}) => {
  const [matches, setMatches] = useState(initialState);
  useEffect(() => {
    const mediaQueryList = window.matchMedia(mediaQueryString);
    // Set matches based on system preferences
    setMatches(!window.matchMedia(mediaQueryString).matches);
    // Listener registered to watch for changes in media query list
    const listener = (event: MediaQueryListEvent) => {
      setMatches(!event.matches);
    };
    mediaQueryList.addEventListener('change', listener);
    return () => {
      mediaQueryList.removeEventListener('change', listener);
    };
  }, [mediaQueryString]);
  return matches;
};

export const usePrefersReducedMotion = () => {
  const mediaQueryString = '(prefers-reduced-motion: no-preference)';
  // Default to reduced motion
  const initialState = true;
  return useMatchMedia({ mediaQueryString, initialState });
};
