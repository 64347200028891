import { Anchor } from '@qogita/ui';
import Image from 'next/legacy/image';

import { vercelImageLoader } from '#lib/url';

import sellToQogitaImg from '../assets/sell-to-qogita.webp';

export const SellPanel = () => {
  return (
    <section className="relative flex flex-col items-center overflow-visible bg-[#99CBC9]">
      <div className="grid w-full max-w-5xl grid-cols-1 gap-4 md:grid-cols-[3fr_2fr] md:gap-0">
        <div className="order-last mb-[-10%] ml-4 mr-4 md:relative md:order-first md:mb-0 md:ml-6 md:mr-0">
          <div className="md:absolute md:top-24 md:-mr-4 md:-mt-4">
            <Image
              loader={vercelImageLoader}
              src={sellToQogitaImg}
              alt="Sell via Qogita"
            />
          </div>
        </div>
        <div className="ml-4 mr-4 pb-2 pt-12 md:ml-0 md:mr-6 md:py-24">
          <p className="text-primary-700 font-bold">Expand your reach</p>
          <h2 className="mt-2 text-4xl font-bold">Sell via Qogita</h2>
          <p className="mt-4 font-bold text-gray-700">
            List your goods on Qogita and unlock access to buyers around the
            world, helping you increase your turnover.
          </p>
          <ul className="marker:text-primary-700 mt-4 list-outside list-disc pl-4 text-gray-700 marker:text-xs">
            <li>Get paid securely</li>
            <li className="mt-2">
              Control all (your) orders in a unified portal
            </li>
            <li className="mt-2">Outsource logistics when you need it</li>
            <li className="mt-2">Get catalog competitiveness feedback</li>
            <li className="mt-2">
              Sell what you have in stock at your own prices
            </li>
          </ul>
          <Anchor
            variant="primaryInverseContained"
            href="https://sellers.qogita.com/"
            className="mt-4 w-full md:w-fit"
          >
            Start selling
          </Anchor>
        </div>
      </div>
    </section>
  );
};
