import { GetServerSideProps } from 'next';

import { Layout } from '#components/Layout';
import { HomePage as HomePageContent } from '#components/pages/HomePage/HomePage';
import { getFlags } from '#lib/featureFlag';

const HomePage = (): JSX.Element => (
  <Layout
    padded={false}
    title="Qogita"
    description="A leading global wholesale supplier that offers a wide variety of products, brands, and categories across geographies."
  >
    <HomePageContent />
  </Layout>
);

export const getServerSideProps: GetServerSideProps = async () => {
  const featureFlagState = await getFlags();
  return {
    props: {
      featureFlagState,
    },
  };
};

export default HomePage;
