import { useEffect, useRef } from 'react';

export const TrustBoxMicroTrustScore = () => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // This work in conjuction with the script loaded in
    // _app.tsx, when it's read window.Trustpilot becomes ready
    // and can be used here to initialise the trustbox
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale="en-US"
      data-template-id="5419b637fa0340045cd0c936"
      data-businessunit-id="61e6a507aa6867337dd09e64"
      data-style-height="20px"
      data-style-width="100%"
      data-theme="light"
    >
      <a
        href="https://www.trustpilot.com/review/www.qogita.com"
        target="_blank"
        rel="noopener"
      >
        Trustpilot
      </a>
    </div>
  );
};
