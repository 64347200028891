import Image, { StaticImageData } from 'next/image';
import Link from 'next/link';

import { vercelImageLoader } from '#lib/url';

import bathAndBodyImg from '../assets/categories/bath-and-body.webp';
import hairCareImg from '../assets/categories/hair-care.webp';
import makeupImg from '../assets/categories/makeup.webp';
import nailCareImg from '../assets/categories/nail-care.webp';
import perfumeImg from '../assets/categories/perfume.webp';
import skinCareImg from '../assets/categories/skin-care.webp';

type Category = {
  name: string;
  image: StaticImageData;
  subCategories: string[];
  query: string;
};

const categories = [
  {
    name: 'Perfume & Cologne',
    image: perfumeImg,
    subCategories: [],
    query: 'Health & Beauty > Personal Care > Cosmetics > Perfume & Cologne',
  },
  {
    name: 'Makeup',
    image: makeupImg,
    subCategories: [],
    query: 'Health & Beauty > Personal Care > Cosmetics > Make-Up',
  },
  {
    name: 'Hair Care',
    image: hairCareImg,
    subCategories: [],
    query: 'Health & Beauty > Personal Care > Haircare',
  },
  {
    name: 'Skin Care',
    image: skinCareImg,
    subCategories: [],
    query: 'Health & Beauty > Personal Care > Cosmetics > Skincare',
  },
  {
    name: 'Nail Care',
    image: nailCareImg,
    subCategories: [],
    query: 'Health & Beauty > Personal Care > Cosmetics > Nail Care',
  },
  {
    name: 'Bath & Body',
    image: bathAndBodyImg,
    subCategories: [],
    query: 'Health & Beauty > Personal Care > Cosmetics > Bath & Body',
  },
];

const CategoryCard = ({ name, image, query }: Category) => {
  return (
    <li className="first:ml-4 last:mr-4 md:first:ml-0 md:last:mr-0">
      <Link
        href={{
          pathname: '/products/',
          query: {
            category: query,
          },
        }}
        className="group flex h-full min-w-[14rem] justify-center rounded-lg bg-gray-100 px-6 py-8 focus-within:shadow-md hover:cursor-pointer hover:shadow md:min-h-[29.5rem] md:min-w-0 md:items-center md:px-8"
      >
        <div className="flex w-full flex-col justify-between gap-y-14 md:justify-normal">
          <h3 className="group-hover:text-primary-700 text-2xl md:text-3xl">
            {name}
          </h3>
          <div className="flex items-center justify-center">
            <Image
              loader={vercelImageLoader}
              src={image}
              alt={name}
              height={168}
            />
          </div>
        </div>
      </Link>
    </li>
  );
};

export const CategoriesPanel = () => {
  return (
    <section className="flex flex-col items-center py-12 md:mx-6 md:py-24">
      <div className="w-full max-w-5xl">
        <h2 className="mx-4 text-center text-4xl font-bold">
          Browse our most popular categories
        </h2>
        <ul className="mt-12 flex list-none gap-4 overflow-x-scroll text-center md:grid md:grid-cols-3 md:gap-6 md:overflow-auto">
          {categories.map((category) => (
            <CategoryCard key={category.name} {...category} />
          ))}
        </ul>
      </div>
    </section>
  );
};
