import {
  BenefitsPanel,
  BrandsPanel,
  CategoriesPanel,
  FaqPanel,
  FinalPanel,
  GetStartedPanel,
  GlobalPanel,
  HeroPanel,
  HowQogitaWorksPanel,
  SellPanel,
  TestimonialsPanel,
  VideoLoop,
} from './panels';

export const HomePage = () => {
  return (
    <main>
      <HeroPanel />
      <VideoLoop />
      <BrandsPanel />
      <TestimonialsPanel />
      <HowQogitaWorksPanel />
      <CategoriesPanel />
      <BenefitsPanel />
      <GetStartedPanel />
      <GlobalPanel />
      <FaqPanel />
      <SellPanel />
      <FinalPanel />
    </main>
  );
};
