import { Testimonial, Testimonials } from '@qogita/ui';

import { TrustBoxMicroTrustScore } from '#components/Trustpilot/MicroTrustScore';

export const TestimonialsPanel = () => {
  return (
    <Testimonials>
      <Testimonials.Header>What our buyers are saying</Testimonials.Header>
      <TrustBoxMicroTrustScore />
      <Testimonials.Grid>
        <Testimonial>
          <Testimonial.Icon />
          <Testimonial.Content>
            Buying from Qogita has a great impact on our business as they have a
            huge range of products at a great price and also their financing
            program help us to grow faster than we could do without it. You
            don&apos;t need another supplier once you have Qogita as a supplier!
          </Testimonial.Content>
          <Testimonial.Name>Anton Arcalianu</Testimonial.Name>
          <Testimonial.JobTitle>
            Founder at Motrade International Ltd
          </Testimonial.JobTitle>
        </Testimonial>
        <Testimonial>
          <Testimonial.Icon />
          <Testimonial.Content>
            Using Qogita has allowed me to quickly source a lot of products with
            minimal hassle and at a good price. It is a huge time saver and
            really lets me focus on growing the business. The interface is
            intuitive and much simpler than juggling all the spreadsheets from
            my other suppliers.
          </Testimonial.Content>
          <Testimonial.Name>Tudor Ailoaei</Testimonial.Name>
          <Testimonial.JobTitle>
            Managing Director at Zimmerman GP SRL
          </Testimonial.JobTitle>
        </Testimonial>
        <Testimonial>
          <Testimonial.Icon />
          <Testimonial.Content>
            Qogita has been a game-changer for my e-commerce business. Its
            extensive resources, expert connections and user friendly interface
            have revolutionized the way I look for lucrative deals. I highly
            recommend Qogita to e-commerce entrepreneurs looking to gain a
            competitive edge in the online marketplace.
          </Testimonial.Content>
          <Testimonial.Name>Orhan Oguz</Testimonial.Name>
          <Testimonial.JobTitle>
            Business Owner at George-Allan Bright Asare
          </Testimonial.JobTitle>
        </Testimonial>
        <Testimonial>
          <Testimonial.Icon />
          <Testimonial.Content>
            I was initially skeptical about partnering with a new wholesale
            procurement company, but Qogita has exceeded all my expectations.
            Their team is responsive, professional, and goes above and beyond to
            meet my specific business needs. With their extensive network and
            expertise, they have helped me streamline my procurement process and
            save both time and money. Qogita is a game-changer, and I
            wouldn&apos;t hesitate to recommend them to anyone looking for a
            reliable wholesale partner.
          </Testimonial.Content>
          <Testimonial.Name>Vien Hoang</Testimonial.Name>
          <Testimonial.JobTitle>
            CEO & Founder at Poppun Limited
          </Testimonial.JobTitle>
        </Testimonial>
      </Testimonials.Grid>
    </Testimonials>
  );
};
