export * from './Benefits';
export * from './Brands';
export * from './Categories';
export * from './Faq';
export * from './Final';
export * from './GetStarted';
export * from './Global';
export * from './Hero';
export * from './HowQogitaWorks';
export * from './HowQogitaWorksVideo';
export * from './Sell';
export * from './Testimonials';
