import { XMarkIcon } from '@heroicons/react/24/solid';
import { Button, Modal } from '@qogita/ui';
import { useRef, useState } from 'react';

import { useVideoReducedMotion } from '#hooks/useVideoReducedMotion';
import { useTrackEvent } from '#lib/report/tracking';

const VideoModal = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const mainVideoRef = useRef<HTMLVideoElement>(null);
  const { trackHomepageVideoStopped } = useTrackEvent();
  return (
    <Modal
      open={open}
      onClose={() => {
        trackHomepageVideoStopped({
          currentTime: mainVideoRef.current?.currentTime ?? 0,
        });
        onClose();
      }}
    >
      <Modal.Panel className="relative !w-[90%] !max-w-[90%]">
        <button
          onClick={() => {
            trackHomepageVideoStopped({
              currentTime: mainVideoRef.current?.currentTime ?? 0,
            });
            onClose();
          }}
          className="absolute -top-12 right-0 z-10 ml-auto p-2 lg:top-0"
        >
          <span className="sr-only">Close video modal</span>
          <XMarkIcon className="h-6 w-6 fill-white lg:fill-gray-600" />
        </button>
        <video
          ref={mainVideoRef}
          controls
          className="h-full w-full"
          autoPlay
          playsInline
        >
          <source
            src="https://qogita-prod.imgix.video/Ougita_all_v9.mp4?fm=mp4"
            type="video/mp4"
          />
        </video>
      </Modal.Panel>
    </Modal>
  );
};

export const VideoLoop = () => {
  const { trackHomepageVideoStarted } = useTrackEvent();
  const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);
  const { videoRef, prefersReducedMotion } = useVideoReducedMotion();
  return (
    <section>
      <VideoModal
        open={isVideoModalOpen}
        onClose={() => setIsVideoModalOpen(false)}
      />
      <article className="max-w-site-content relative mx-auto aspect-square md:aspect-auto">
        <button
          onClick={() => {
            trackHomepageVideoStarted();
            setIsVideoModalOpen(true);
          }}
          className="relative flex h-full w-full cursor-[url('/play.svg')_48_48,_pointer] items-center justify-center lg:pointer-events-auto"
          aria-label="Open video"
        >
          <span className="to-primary-700 absolute left-0 top-0 h-full w-full bg-gradient-to-r from-[#fde8ec] opacity-20" />
          <video
            ref={videoRef}
            autoPlay={!prefersReducedMotion}
            loop
            muted
            className="h-full w-full object-cover opacity-20"
            playsInline
            poster="./videoLoopStill.webp"
          >
            <source
              src="https://qogita-prod.imgix.video/qogita-brand-loop.mp4?fm=mp4"
              type="video/mp4"
            />
          </video>
        </button>
        <div className="pointer-events-none absolute left-[50%] top-[50%] flex w-full -translate-x-1/2 -translate-y-1/2 flex-col items-center gap-6 p-6 lg:p-12">
          <h1 className="text-center text-5xl font-bold md:mb-4">
            How Qogita works
          </h1>
          <Button
            onClick={() => {
              trackHomepageVideoStarted();
              setIsVideoModalOpen(true);
            }}
            className="pointer-events-auto lg:hidden"
          >
            Watch video
          </Button>
        </div>
      </article>
    </section>
  );
};
