import { useEffect, useRef } from 'react';

import { usePrefersReducedMotion } from './useMatchMedia';

export const useVideoReducedMotion = () => {
  const prefersReducedMotion = usePrefersReducedMotion();
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    // We handle this in an effect because the `autoplay` attribute
    // of the video element is not stateful and so change it doesn't trigger the
    // video to play.
    const handleReducedMotionChange = async () => {
      try {
        if (videoRef.current) {
          if (prefersReducedMotion) {
            videoRef.current.pause();
          } else {
            await videoRef.current.play();
          }
        }
      } catch {
        return;
      }
    };
    handleReducedMotionChange();
  }, [prefersReducedMotion]);

  return {
    videoRef,
    prefersReducedMotion,
  };
};
