import { NextAnchor } from '#components/NextAnchor';

export const GetStartedPanel = () => {
  return (
    <section className="flex justify-center bg-gray-100 px-6 py-24 md:py-36">
      <div className="mx-4 w-full max-w-xl text-center md:mx-6 md:py-6">
        <h2 className="text-center text-4xl font-bold">
          Ready to get started?
        </h2>
        <p className="mt-6 text-3xl">
          Join 10,000+ wholesale buyers who’ve already transformed their
          procurement processes on Qogita
        </p>
        <NextAnchor
          href={{
            pathname: '/register/',
          }}
          variant="primaryContained"
          className="mt-6 w-full md:w-fit"
        >
          Sign up for free
        </NextAnchor>
      </div>
    </section>
  );
};
