import { Accordion } from '@qogita/ui';

export const FaqPanel = () => {
  return (
    <section className="flex flex-col items-center bg-gray-100 py-12 md:py-24">
      <div className="w-full max-w-5xl">
        <h2 className="mx-4 mb-12 text-center text-4xl font-bold md:mx-6 md:mb-8">
          Frequently asked questions
        </h2>
        <Accordion closeOthers={false} separator={false}>
          <Accordion.Item name="ordering" className="mt-6">
            <Accordion.Button>
              How does the ordering process work?
            </Accordion.Button>
            <Accordion.Panel>
              We’ve designed wholesale ordering on Qogita to remove all the
              hassle for you. At check out, our technology will divide your
              order among the best suppliers based on pricing, your location and
              inventory availability. After that, we take care of the logistics
              and paperwork, so you should never have to handle shipping
              processes yourself.
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item name="best-price" className="mt-6">
            <Accordion.Button>
              How do you guarantee me the best price on each product?
            </Accordion.Button>
            <Accordion.Panel>
              We’ll do our best to get you the best price. Working with
              thousands of suppliers across multiple regions gives you access to
              deals not otherwise found.
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item name="suppliers" className="mt-6">
            <Accordion.Button>
              Which suppliers do you work with?
            </Accordion.Button>
            <Accordion.Panel>
              Qogita only works with pre-vetted, quality wholesalers who can
              adhere to the highest order processing and delivery standards. We
              check to ensure they stock the highest quality, original products.
              Our suppliers are located across the EU, US and the UK. We also
              manage your shipment directly from the supplier, ensuring the
              quickest and safest delivery possible.
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item name="shipment" className="mt-6">
            <Accordion.Button>How soon will my order arrive?</Accordion.Button>
            <Accordion.Panel>
              Generally within 1 week. If you’ve opted to purchase from regions
              outside of your shipping location (import), some deliveries may
              take up to 2 weeks. Because our technology optimizes your order
              allocation, your order may come from multiple suppliers. If this
              occurs, your order is highly likely to arrive in multiple
              shipments.
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item name="regions" className="mt-6">
            <Accordion.Button>
              Can I choose the regions I want to buy from?
            </Accordion.Button>
            <Accordion.Panel>
              Yes! Qogita currently works with suppliers in the EU, UK and US.
              You can pick and select individual countries to buy from on your
              profile page. Please note, that by selecting regions outside of
              your country&apos;s shipping location (import), you should be able
              to clear goods from customs.
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item name="categories" className="mt-6">
            <Accordion.Button>
              Which product categories do you offer?
            </Accordion.Button>
            <Accordion.Panel>
              Qogita has millions of unique products across numerous Health and
              Beauty categories. Currently, most of our products are perfumes,
              nail care, healthcare, make up, bath & body and skin scare.
              However, we continuously expand our product offering across
              additional categories and geographies.
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item name="order-limits" className="mt-6">
            <Accordion.Button>
              Do you have a minimum and maximum order value?
            </Accordion.Button>
            <Accordion.Panel>
              In most cases, we require a minimum order value of €1,000. This
              may be amended from time to time based on current demand and
              supply, among other factors.
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item name="cancel" className="mt-6">
            <Accordion.Button>Can I cancel my order?</Accordion.Button>
            <Accordion.Panel>
              All orders on Qogita are final. Since your order is processed
              immediately after you confirmed it, we are unable to accept order
              cancellation.
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item name="taxes" className="mt-6">
            <Accordion.Button>
              Do I have to pay taxes or any other fees?
            </Accordion.Button>
            <Accordion.Panel>
              We charge Value Added Tax (VAT) (or sales tax) whenever legally
              required.
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item name="discounts" className="mt-6">
            <Accordion.Button>
              Are there discounts on larger orders?
            </Accordion.Button>
            <Accordion.Panel>
              We always strive to offer the lowest available price, regardless
              of the order size. As such, we do not offer additional discounts
              for large orders.
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item name="financing" className="mt-6">
            <Accordion.Button>
              Do you offer financing and what are your payment terms?
            </Accordion.Button>
            <Accordion.Panel>
              Once you’ve signed up to Qogita, you can apply for financing. Our
              team will ask you for more information about your business (such
              as financial statements), and run a credit check through our
              financial partner. Eligible buyers are then given a credit line
              based on the report. You can access your credit page in your
              account dashboard. We know that every business is unique, so we
              offer flexible financing to suit your needs. Our financing fees
              are very competitive and vary depending on the terms you choose at
              checkout - 30,60 or 90 days, per your choice.
            </Accordion.Panel>
          </Accordion.Item>
          <Accordion.Item name="price" className="mt-6">
            <Accordion.Button>When is the price final?</Accordion.Button>
            <Accordion.Panel>
              The final price of all order components is calculated and clearly
              shown at checkout.
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </div>
    </section>
  );
};
