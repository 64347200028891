import Image from 'next/legacy/image';

import { NextAnchor } from '#components/NextAnchor';
import { vercelImageLoader } from '#lib/url';

import globalImg from '../assets/global-graphic.webp';

export const GlobalPanel = () => {
  return (
    <section className="bg-marketing-primary-dark relative flex flex-col items-center overflow-hidden md:overflow-visible">
      <div className="grid w-full max-w-5xl grid-cols-1 gap-x-14 md:grid-cols-2">
        <div className="md:flex md:items-center md:justify-center md:py-4 lg:relative">
          <div className="absolute right-[-184px] top-[-108px] w-[360px] max-w-full md:relative md:right-auto md:top-auto md:ml-6 md:mt-4 lg:absolute lg:right-0 lg:top-24 lg:mt-0 lg:w-[90%]">
            <Image loader={vercelImageLoader} src={globalImg} alt="Globe" />
          </div>
        </div>
        <div className="mx-4 pb-12 pt-24 md:mr-6 md:py-24">
          <p className="text-primary-300 font-bold">Endless resources</p>
          <h2 className="mr-[30%] mt-2 text-4xl font-bold text-white md:mr-0">
            Global procurement made easy
          </h2>
          <p className="mt-9 text-gray-200 md:mt-4">
            With suppliers located in 30+ countries, we find the best way to get
            the goods you need. Navigate the global catalog and get an edge over
            your competition.
          </p>
          <NextAnchor
            href={{
              pathname: '/register/',
            }}
            variant="primaryInverseContained"
            className="mt-9 w-full md:mt-4 md:w-fit"
          >
            Create an account
          </NextAnchor>
        </div>
      </div>
    </section>
  );
};
