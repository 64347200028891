import Image from 'next/legacy/image';

import { vercelImageLoader } from '#lib/url';

import howQogitaWorksImg from '../assets/how-qogita-works.webp';
import howQogitaWorksMobileImg from '../assets/how-qogita-works-mobile.webp';

export const HowQogitaWorksPanel = () => {
  return (
    <section className="flex flex-col items-center bg-gray-100 pb-16 pt-8 md:pb-24 md:pt-24">
      <article className="mx-4 flex max-w-5xl flex-col md:mx-6 md:flex-row md:gap-x-3">
        <div className="md:basis-2/5">
          <p className="text-primary-700 font-bold">
            Optimize your procurement
          </p>
          <h2 className="mt-2 text-4xl font-bold">How Qogita works</h2>
          <p className="mt-6 text-gray-700 md:mt-4">
            Our cutting-edge technology seamlessly substitutes the work of a
            large procurement team. Instead of finding potential suppliers, and
            negotiating with each, all your product needs are here and at the
            best price available.
          </p>
          <p className="mt-6 font-bold text-gray-700 md:mt-4">
            Here’s how it goes:
          </p>
          <ol className="pl-4.5 marker:text-primary-700 mt-6 list-outside list-decimal space-y-4 tracking-wide text-gray-700 md:mt-4">
            <li className="pl-3">
              Start by filling your cart with all desired items, choosing from
              millions of products
            </li>
            <li className="pl-3">
              Qogita then finds the best available prices for all your product
              needs, and presents you with a single, organized cart
            </li>
            <li className="pl-3">
              All that’s left for you to do is to choose how and when to pay,
              then focus on selling while we take care of everything
            </li>
          </ol>
          <div className="mt-8 block md:hidden">
            <Image
              loader={vercelImageLoader}
              src={howQogitaWorksMobileImg}
              layout="intrinsic"
              width={666}
              height={624}
              alt="How Qogita works"
            />
          </div>
          <p className="text-primary-700 mt-6 text-center font-bold md:mt-8 md:text-left">
            All that time spent comparing deals from different sellers,
            performed in the blink of an eye. Compete with large companies and
            build your business on Qogita.
          </p>
        </div>
        <div className="-mr-3 hidden basis-3/5 items-end justify-end md:flex md:items-center">
          <Image
            loader={vercelImageLoader}
            src={howQogitaWorksImg}
            layout="intrinsic"
            width={666}
            height={624}
            alt="How Qogita works"
          />
        </div>
      </article>
    </section>
  );
};
