import clsx from 'clsx';
import Image from 'next/legacy/image';
import Link from 'next/link';

import { vercelImageLoader } from '#lib/url';

import chanelLogo from '../assets/brands/chanel.webp';
import dolceGabbanaLogo from '../assets/brands/dolce-gabbana.webp';
import garnierLogo from '../assets/brands/garnier.webp';
import lorealLogo from '../assets/brands/loreal.webp';
import maybellineLogo from '../assets/brands/maybelline.webp';
import niveaLogo from '../assets/brands/nivea.webp';
import oralBLogo from '../assets/brands/oral-b.webp';
import schwarzkopfLogo from '../assets/brands/schwarzkopf.webp';
import theBodyShopLogo from '../assets/brands/the-body-shop.webp';
import versaceLogo from '../assets/brands/versace.webp';

const brands = [
  { name: 'Maybelline New York', logo: maybellineLogo, visibleOnMobile: true },
  { name: 'Garnier', logo: garnierLogo, visibleOnMobile: true },
  { name: 'Dolce & Gabbana', logo: dolceGabbanaLogo, visibleOnMobile: true },
  { name: 'Nivea', logo: niveaLogo, visibleOnMobile: true },
  { name: 'Versace', logo: versaceLogo, visibleOnMobile: true },
  { name: 'Chanel', logo: chanelLogo, visibleOnMobile: true },
  { name: "L'Oréal", logo: lorealLogo },
  { name: 'Schwarzkopf', logo: schwarzkopfLogo },
  { name: 'Oral-B', logo: oralBLogo },
  { name: 'The Body Shop', logo: theBodyShopLogo },
];

export const BrandsPanel = () => {
  return (
    <section className="flex flex-col items-center pb-12 pt-12 md:pb-24">
      <div className="mx-4 max-w-5xl md:mx-6">
        <h2 className="text-center text-4xl font-bold">Our popular brands</h2>
        <ul className="mt-14 grid list-none grid-cols-2 gap-4 md:grid-cols-5 md:gap-x-6 md:gap-y-12">
          {brands.map((brand) => (
            <li
              key={brand.name}
              className={clsx(
                'rounded shadow hover:shadow-lg',
                brand.visibleOnMobile ? 'block' : 'hidden md:block',
              )}
            >
              <Link
                href={{
                  pathname: '/products/',
                  query: {
                    brand_name: brand.name,
                  },
                }}
                className="flex h-full items-center justify-center p-1"
              >
                <Image
                  loader={vercelImageLoader}
                  src={brand.logo}
                  alt={brand.name}
                />
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
};
